/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

img {
	height: auto; // Make sure images are scaled correctly.
	max-width: 100%; // Adhere to container width.
}

figure {
	margin: 1em 0; // Extra wide images within figure tags don't overflow the content area.
}

.image_size {
	&-small {
		max-width: 300px;
	}
	&-medium {
		max-width: 54%;
	}
	&-large {
		max-width: 88%;
	}
	&-full {
		max-width: 100%;
	}
}
