// Sana icon font 

@font-face {
  font-family: '#{$sana-icon-font-family}';
  src:  url('#{$sana-icon-font-path}/#{$sana-icon-font-family}.eot?z1x0z7');
  src:  url('#{$sana-icon-font-path}/#{$sana-icon-font-family}.eot?z1x0z7#iefix') format('embedded-opentype'),
    url('#{$sana-icon-font-path}/#{$sana-icon-font-family}.ttf?z1x0z7') format('truetype'),
    url('#{$sana-icon-font-path}/#{$sana-icon-font-family}.woff?z1x0z7') format('woff'),
    url('#{$sana-icon-font-path}/#{$sana-icon-font-family}.svg?z1x0z7##{$sana-icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


[class^="icon-"], [class*=" icon-"] {
  // use !important to prevent issues with browser extensions that change fonts 
  font-family: '#{$sana-icon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // Better font rendering 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-green-check {
  &:before {
    content: $icon-green-check;     
    color: #3e8893; // icon has set color 
  }
}
.icon-grey-x {
  &:before {
    content: $icon-grey-x;     
    color: #999; // icon has set color 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link; 
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-circle-question {
  &:before {
    content: $icon-circle-question; 
  }
}
.icon-circle-info {
  &:before {
    content: $icon-circle-info; 
  }
}
.icon-circle-exclamation {
  &:before {
    content: $icon-circle-exclamation; 
  }
}
.icon-circle-checkmark {
  &:before {
    content: $icon-circle-checkmark; 
  }
}
.icon-circle-cancel {
  &:before {
    content: $icon-circle-cancel; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-checkmark {
  &:before {
    content: $icon-checkmark; 
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-bell-horn {
  &:before {
    content: $icon-bell-horn; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}

