.comparisonTripleFeatureCard {
	position: relative;
	padding: ($spacing_unit * 2) 0;

	// wp block editor 
	&.-customColor {
		background: inherit;
	}

	@include tablet {
		padding: ($spacing_unit * 3) 0;
	}

	@include laptop {
		padding: ($spacing_unit * 4) 0;
	}
	
	&__heading {
		margin-bottom: $spacing_unit;
		text-align: center;

		@include tablet {
			margin-bottom: $spacing_unit * 2;
		}
	}

	&__title {
		font-family: $font__bold;
		font-weight: 400;
			font-size: 40px;
			line-height: 44px;
		letter-spacing: -0.5px;
		margin: 0 0 $spacing_unit;

		// wp block color override
		&.-customColor {
			color: inherit;
		}
	}

	&__copy {
		font-size: 18px;
		line-height: 26px;
		color: $color__black;
		max-width: 540px;
		margin: 0 auto;
	}

	&__row {
		// nothing fancy until tablet size 

		@include tablet {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: $spacing_unit;
		}

		@include laptop {
			gap: $spacing_unit * 1.5;
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		flex: 1;
		background: $color__offwhite;
		border-radius: $border_radius * 2;
		padding: ($spacing_unit * 1.5) ($spacing_unit * 1.5) 0;
		margin-bottom: $spacing_unit * 3;

		// &:last-of-type {
		// 	margin-bottom: 0;
		// }

		@include tablet {
			padding: $spacing_unit $spacing_unit 0;
			margin-bottom: $spacing_unit * 2;
		}

		@include desktop {
			padding: ($spacing_unit * 1.5) ($spacing_unit * 1.5) 0;
		}
	}

	&__cardHeading {
		font-family: $font__bold;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.5px;
		color: $color__brand-dk-green;
		margin-bottom: $spacing_unit * .75;
	}

	&__cardList {
		position: relative;
		flex-grow: 1;

		@include tablet {
			margin-bottom: -($spacing_unit / 2);
		}
	}

	&__cardListItem {
		font-size: 18px;
		line-height: 22px;
		color: $color__black;
		position: relative;
		padding-left: $spacing_unit *.75;
		margin-bottom: $spacing_unit / 2;

		.icon-plus {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			color: $color__brand-dk-green2;
		}
	}

	&__cardFooter {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		gap: $spacing_unit / 2;
		position: relative;
		bottom: -50px;
	}

	&__cardFooterBox {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		flex: 1;
		justify-content: center;
		align-items: center;
		border-radius: $border_radius;
		height: 100px;

		&.-sana {
			border: 2px solid $color__brand-yellow;
			background: $color__brand-yellow;
		}

		&.-legacy {
			border: 1px solid $color__black;
			background: $color__white;
		}
	}


	&__cardFooterTitle {
		font-family: $font__bold;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: $spacing_unit / 8;
		color: $color__brand-dk-green;

		.-legacy & {
			color: $color__brand-md-gray;
		}
	}

	&__cardFooterPrice {
		font-family: $font__bold;
		font-weight: 400;
		font-size: 28px;
		line-height: 34px;
		letter-spacing: -0.5px;
		color: $color__brand-dk-green;

		.-legacy & {
			color: $color__brand-md-gray;
		}
	}



/* Teal/400* */


	// &__cardHeading {
	// 	font-family: $font__bold;
	// 	font-weight: 700;
	// 	font-size: 32px;
	// 	line-height: 36px;
	// 	letter-spacing: -0.5px;
	// 	margin: 0 0 ($spacing_unit * 1.5);

	// 	// wp block color override
	// 	&.-customColor {
	// 		color: inherit;
	// 	}
	// }

	// &__cardItems {
	// 	display: flex; 
	// 	flex-direction: row;
	// 	justify-content: flex-start;
	// 	align-items: center;
	// 	margin-bottom: $spacing_unit * .75;

	// 	&:last-of-type {
	// 		margin: 0;
	// 	}
	// }

	// &__cardItemImg {
	// 	width: 76px;
	// 	height: 76px;
	// 	margin-right: $spacing_unit * .75;
	// 	flex-shrink: 0;

	// 	img {
	// 		display: block;
	// 		margin: 0;
	// 	}
	// }

	// &__cardItemDesc {
	// 	font-family: $font__bold;
	// 	font-weight: 400;
	// 	font-size: 20px;
	// 	line-height: 24px;
	// 	letter-spacing: -0.5px;

	// 	color: $color__black;
	// }
}
