
// styles specific to Ninja forms 
.ninjaForm {
	padding: 0;

	.nf-form-content {
		padding: 0;
	}

	#ninja_forms_required_items.hidden-container, 
	.nf-field-container.hidden-container {
		height: 0;
	}

	form {
		width: 100% !important;

		.nf-field-label {
			margin: 0 !important;
		}

		.ninja-forms-req-symbol {
			color: $color__brand-red;
			padding-right: 5px;
			display: inline-block;
		}

		.nf-form-fields-required {
			margin-bottom: ($spacing_unit * .5) !important;

			.ninja-forms-req-symbol {
				padding-right: 0;
			}
		}

		label {
			display: block;
			width: 100% !important;
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			font-size: 12px;
			line-height: 24px;
			// color: $color__black;
			color: inherit; // inherit color of the block 
		}

		.list-select-wrap .nf-field-element > div,
		input[type="text"],
		input[type="email"],
		input[type="url"],
		input[type="password"],
		input[type="search"],
		input[type="number"],
		input[type="tel"],
		input[type="range"],
		input[type="date"],
		input[type="month"],
		input[type="week"],
		input[type="time"],
		input[type="datetime"],
		input[type="datetime-local"],
		input[type="color"],
		select.ninja-forms-field,
		textarea {
			display: block; 
			width: 100% !important;
			color: $color__text-input;
			border: 1px solid $color__black;
			font-size: 16px;
			line-height: 24px;
			border-radius: $border_radius;
			overflow: hidden;
			padding: 12px 24px;
			box-shadow: none;
			min-height: 50px;
			background-color: $color__white;

			&::placeholder {
				color: $color__brand-md-gray2;
			}

			&:focus {
				color: $color__text-input-focus;
				border-color: $color__brand-primary;
				outline-color: $color__brand-primary;
			}
		}

		select.ninja-forms-field {
			display: block !important;
			width: 100% !important;
			max-width: 100% !important;
			color: $color__text-input !important;
			border: 1px solid $color__black !important;
			font-size: 16px !important;
			line-height: 24px !important;
			border-radius: $border_radius !important;
			padding: 12px 24px !important;
			text-transform: none !important;
			box-shadow: none !important;
		}

		textarea {
			display: block; 
			width: 100% !important;
			border: 1px solid $color__black;
			box-shadow: none;
		}

		.nf-field-element input[type="submit"] {
			@extend .button;
			margin: ($spacing_unit * .75) 0 0 !important;
		}

	}

}



.newsletterSignup__form.ninjaForm {
	form {
		.nf-field-container {
			margin-bottom: 0 !important;
		}

		@include tablet {
			.nf-field-element input[type=submit] {
				margin: 0 !important;
			}

			nf-fields-wrap {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				justify-content: space-between;
				position: relative;
				gap: $spacing_unit;

				// offset to account for label 
				top: -12px;
			}
		}

		nf-field:first-of-type {
			flex-grow: 1;
		}
	}
}






