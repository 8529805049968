//--------------------------------------------------------------
// TABLE OF CONTENTS:
//--------------------------------------------------------------

/*
# Generic
	- Normalize
	- Box sizing
# Base
	- Typography
	- Elements
	- Links
	- Forms
## Layouts
# Components
	- Navigation
	- Posts and pages
	- Comments
	- Widgets
	- Media
	- Captions
	- Galleries
# plugins
	- Jetpack infinite scroll
# Utilities
	- Accessibility
	- Alignments

--------------------------------------------------------------*/

// Import variables and mixins.
// COLORS
//

@import 'abstracts/variables';
@import 'abstracts/mixins';

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/

/* Base
--------------------------------------------- */
@import 'base/normalize';
@import 'base/body';
@import 'base/font-stack';
@import 'base/typography';
@import 'base/headings';
@import 'base/copy';
@import 'base/icons';
@import 'base/hr';
@import 'base/lists';
@import 'base/media';
@import 'base/tables';
@import 'base/links';
@import 'base/buttons';
@import 'base/fields';

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import 'components/block-hero';
@import 'components/block-hero-landing';
@import 'components/block-wysiwyg-text';
@import 'components/block-wysiwyg-repeater';
@import 'components/block-logo-cloud';
@import 'components/block-multi-column-module';
@import 'components/block-partners';
@import 'components/block-text-image';
@import 'components/block-stripe-card-overlay';
@import 'components/block-plan-suggestion-module';
@import 'components/block-comparison-total-card';
@import 'components/block-comparison-icon-card';
@import 'components/block-comparison-qa-card';
@import 'components/block-comparison-competitor-card';
@import 'components/block-comparison-triple-feature-card';
@import 'components/block-accordion-rows';
@import 'components/block-vertical-tabs';
@import 'components/block-collapse-plan-comparison';
@import 'components/block-cta-banner';
@import 'components/block-cta-banner-dismissable';
@import 'components/block-testimonial-carousel';
@import 'components/block-case-study-banner';
@import 'components/block-multi-column-cards';
@import 'components/block-featured-posts';
@import 'components/block-video';
@import 'components/block-jump-link';
@import 'components/social-icons';
@import 'components/block-get-quote';
@import 'components/block-styled-blockquote';
@import 'components/marketo-form-styles'; // styles specific to marketo forms 
@import 'components/ninja-form-styles'; // styles specific to ninja forms 

// styled blockquote 
// request demo 

// @import 'components/custom-post-press';
@import 'components/header';
@import 'components/footer';
@import 'components/navigation';
@import 'components/pagination';
@import 'components/search'; // sitewide search in header nav 
// @import 'components/scrollup'; // scroll to top button 
@import 'components/posts-and-pages';
@import 'components/comments';
@import 'components/widgets';
@import 'components/media';
@import 'components/captions';
@import 'components/galleries';

@import 'layouts/columns';

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/

/* Jetpack infinite scroll
--------------------------------------------- */
@import 'plugins/jetpack/infinite-scroll';

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/

/* Accessibility
--------------------------------------------- */
@import 'utilities/accessibility';

/* Alignments
--------------------------------------------- */
@import 'utilities/alignments';

/* Vendor
--------------------------------------------- */
// @import 'vendor/choices';
@import 'vendor/flickity';
@import 'vendor/lever-jobs-board';
