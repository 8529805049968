.comparisonQaCard {
	position: relative;
	padding: ($spacing_unit * 2) 0;

	// wp block editor 
	&.-customColor {
		background: inherit;
	}

	@include widetablet {
		padding: ($spacing_unit * 3) 0;
	}

	&__container {
		position: relative;
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0 $spacing_unit;
		max-width: $spacing_unit * 33.2815;
	}

	&__cardTable {
		padding: ($spacing_unit * 2) ($spacing_unit * 1.5);
		background: $color__white;
		border: 1px solid $color__black;
		border-radius: $border_radius;
		overflow: hidden;

		@include widetablet {
			padding: ($spacing_unit * 2) ($spacing_unit * 1.25) ($spacing_unit * 2) ($spacing_unit * 2);
			margin-bottom: 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: $spacing_unit * 2.5;
		}
	}

	&__heading {
		margin-bottom: ($spacing_unit * 1.25);

		@include widetablet {
			flex: 1;
			max-width: 23rem;
			margin-bottom: 0;
		}
	}

	&__copy {
		margin-bottom: ($spacing_unit * .5);

		&.-customColor {
			color: inherit;

			h1, h2, h3, h4, h5, h6 {
				color: inherit;
			}
		}
	}

	&__image {
		display: block;
	}

	&__qaList {
		@include widetablet {
			flex: 1;
		}
	}

	&__qaListItem {
		border-top: 1px solid $color__black;
		padding: ($spacing_unit * .75) 0;

		&:first-of-type {
			border: none;
			padding-top: 0;
		}

		&:last-of-type {
			padding-bottom: 0;
		}
	}

	&__question {
		font-family: $font__bold;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.2;
		letter-spacing: -0.5px;
		color: $color__brand-dk-green;
		margin-bottom: ($spacing_unit * .25);

		&.-customColor {
			color: inherit;
		}
	}

	&__answer {
		font-family: $font__main;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.4;

		&.-customColor {
			color: inherit;
		}
	}
}
