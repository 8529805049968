.wysiwygRepeater {
  position: relative;
  padding: ($spacing_unit * 2) 0;

  @include tablet {
    padding: ($spacing_unit * 3) 0;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: $container_max_width;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $spacing_unit;
  }


  &__heading {
    margin-bottom: $spacing_unit * 1.5;

    @include tablet {
      margin-top: $spacing_unit;
      // margin-bottom: $spacing_unit * 2.5;
    }
  }

  &__title {
    margin-bottom: 0;

    // wp block color override
    &.-customColor {
      color: inherit;
    }
  }

  &__text {
    font-family: $font__bold;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin-bottom: $spacing_unit * 1.5;
    
    // wp block color override
    &.-customColor {
      color: inherit;
    }
  }

  &__row {
    // border: 3px solid teal;
    
    @include tablet {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin: 0;
    }
  }

  &__textBlockMain {
    // border: 3px solid blue;

    &.-sidePresent {
      margin-bottom: $spacing_unit;

      @include tablet {
        margin-bottom: 0;

        // if sidebar present and content is not a single row, max out columns at 50% 
        > * {
          flex-basis: 50%;
        }
      }
    }
    
    @include phablet {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex: 1;
      margin: 0;
      margin-left: -$spacing_unit / 2;
      margin-right: -$spacing_unit / 2;

      > * {
        flex-basis: 50%;
      }

      &.-oneCol {
        > * {
          flex-basis: 100%;
        }
      }
    }

    @include tablet {
      > * {
        flex-basis: 33.3333%;
      }

      &.-twoCol {
        > * {
          flex-basis: 50%;
        }
      }

      &.-fourCol {
        > * {
          flex-basis: 25%;
        }
      }

      &.-sidePresent {
        margin-right: 0;
      }
    }

  }

  &__textBlockSide {
    flex: 1;

    // border: 3px solid pink; 

    @include phablet {
    margin-left: -$spacing_unit / 2;
    margin-right: -$spacing_unit / 2;
    }

    @include tablet {
      max-width: 33.3333%;
      margin-left: 0;
      margin-right: -$spacing_unit / 2;
    }
  }

  &__textBlock {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: $spacing_unit;

    @include phablet {
      padding: 0 ($spacing_unit / 2);
    }
  }

  &__textBlockInner {
    // background: $color__white;

    // wp block color override
    &.-customColor {
      color: inherit;

      h1, h2, h3, h4, h5, h6 {
        color: inherit;
      }
    }

    *:last-child {
      margin-bottom: 0;
    }
  }



  // mimic card styles if sidebar card option is selected 
  &__block {
    text-align: left;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    font-family: $font__main;
    margin-bottom: $spacing_unit;

    @include tablet {
      display: flex;
      flex-direction: column;

    }

    @include laptop {
      margin: 0;
    }
  }

  &__blockBorder {
    position: relative;
    flex-grow: 1;
    padding: ($spacing_unit * .6) ($spacing_unit * .6) ($spacing_unit * 1.1);
    transition: $transition_duration;
    border-radius: $border_radius;
    overflow: hidden;
    border: 1px solid transparent;
    background: $color__offwhite;

    @include tablet {
      display: flex;
      flex-direction: column;
    }

    // if link is present, add hover effect 
    &.-hasLink {
      &:hover {
        cursor: pointer;
        background: $color__offwhite;
        border-color: $color__black;
        box-shadow: $box__shadow;
      }
    }
  }

  &__image {
    margin-bottom: $spacing_unit * .75;

    img {
      display: block;
      margin: 0 auto;
    }
  }

  &__blockTextWrap {
    margin-bottom: $spacing_unit / 2;

    @include tablet {
      flex-grow: 1;
    }
  }

  &__blockEyebrow {
    font-family: $font__bold;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: $color__black;
    margin-bottom: $spacing_unit / 3;

    a {
      color: inherit;
      border: none;
    }
  }

  &__blockTitle {
    color: $color__brand-dk-green;
    margin-bottom: $spacing_unit / 2;
    font-family: $font__bold;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
  }

  &__blockText {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5em;
    margin: 0;
    transition: color 0.3s ease-out;
  }

  &__blockLink {
    font-size: 16px;
    line-height: 24px;
    color: $color__brand-blue;
    transition: $transition_duration;
    text-decoration: none;
    border: none;

    &::before {
      content: '';
      cursor: pointer;
      position: absolute;
      inset: 0;
    }
  }
}
