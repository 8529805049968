.partnersBlock {
	position: relative;
	padding: ($spacing_unit * 2) 0;

	@include tablet {
		padding: ($spacing_unit * 3) 0;
	}

	&__title {
		font-family: $font__bold;
		font-weight: 400;
		font-size: 32px;
		line-height: 36px;
		letter-spacing: -0.5px;

		// wp block color override
		&.-customColor {
			color: inherit;
		}

		@include tablet {
			margin-bottom: $spacing_unit * 2;
		}
	}

	&__container {
		position: relative;
		width: 100%;
		max-width: $container_max_width;
		margin-left: auto;
		margin-right: auto;
		padding: 0 $spacing_unit;
	}

	&__inner {
		@include phablet {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: 0;
			// margin-left: -$spacing_unit / 2;
			// margin-right: -$spacing_unit / 2;
			margin-left: -$spacing_unit * .75;
			margin-right: -$spacing_unit * .75;

			> * {
				flex-basis: 50%;
			}
		}

		@include tablet {
			> * {
				flex-basis: 33.3333%;
			}
		}

		@include laptop {
			> * {
				flex-basis: 25%;
			}
		}
	}

	&__block {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		text-align: center;
		font-family: $font__main;
		margin-bottom: $spacing_unit * 1.5;
		display: flex;
		flex-direction: column;
		// padding: 0 ($spacing_unit / 2);
		padding: 0;

		@include phablet {
			padding: 0 ($spacing_unit * .75);
		}
	}

	&__blockBorder {
		position: relative;
		flex-grow: 1;
		padding: ($spacing_unit / 2) $spacing_unit $spacing_unit;
		transition: $transition_duration;
		border-radius: $border_radius * 2;
		overflow: hidden;
		border: 1px solid $color__black;
		background: $color__white;

		@include tablet {
			display: flex;
			flex-direction: column;
		}

		// if link is present, add hover effect 
		&.-hasLink {
			&:hover {
				cursor: pointer;
				box-shadow: $box__shadow;
			}
		}
	}

	&__image {
		height: 140px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			display: block;
			align-self: center;
			max-width: 146px;
			margin: 0 auto;
		}
	}

	&__blockTextWrap {
		text-align: center; 
		margin: 0 auto;
		height: 50px;

		@include tablet {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
	}

	&__blockLink {
		display: inline-block;
			align-self: flex-end;
		text-decoration: underline;
		border: none;
		font-size: 16px;
		line-height: 24px;
		transition: $transition_duration;
		margin: 0 auto;

		&:hover {
		text-decoration: underline;
		}

		&::before {
			content: '';
			cursor: pointer;
			position: absolute;
			inset: 0;
		}
	}
}
