.comparisonCompetitorCard {
	position: relative;
	padding: ($spacing_unit * 2) 0;

	background: $color__brand-yellow;

	// wp block editor 
	&.-customColor {
		background: inherit;
	}


	&__container {
		@extend .container;
	}

	&__row {
		// nothing fancy until wide tablet size 
		@include widetablet {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: $spacing_unit;
		}
	}

	&__competitorCard {
		position: relative;
		padding: ($spacing_unit * 1) ($spacing_unit * 1.5) ($spacing_unit * 1.5);
		background: $color__white;

		border-radius: ($spacing_unit * .75);
		overflow: hidden;

		box-shadow: 10px 10px darken($color__brand-yellow, 15%);
    transform: translate(-0.25rem, -0.25rem);
    z-index: 1;

		@include widetablet {
			padding: ($spacing_unit * .75) ($spacing_unit * 1.25) ($spacing_unit * 1.25) ($spacing_unit * 2);
			margin-bottom: 0;
		}
	}


	&__heading {
		margin-bottom: ($spacing_unit * 1.25);

		@include widetablet {
			flex: 1;
			max-width: 27rem;
			margin-bottom: 0;
		}
	}

	&__copy {
		margin-bottom: ($spacing_unit * .5);

		&.-customColor {
			color: inherit;

			h1, h2, h3, h4, h5, h6 {
				color: inherit;
			}
		}

		*:last-child {
			margin-bottom: 0;
		}
	}

	&__competitorCard {
		@include widetablet {
			flex: 1;
		}
	}


	&__trustpilot {
		display: flex;
		justify-content: flex-end;
	}

	&__trustpilotLogo {
		width: 30%;

		img {
			display: block;
			width: 110px;
			margin-bottom: ($spacing_unit * .3);
		}
	}

	&__competitorCardItem {
		border-top: 1px solid $color__background-hr;
		padding: ($spacing_unit * .5) 0;

		display: flex; 
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;


		&.-firstItem {
		padding: ($spacing_unit * .65) 0;
		}

		&:last-of-type {
			padding-bottom: 0;
		}
	}


	&__logo {
		width: 40%;
		padding-right: ($spacing_unit * 2);
		display: flex;
    align-items: center;
    justify-content: flex-start;

		.-firstItem & {
			padding-right: ($spacing_unit * 1);
		}
	}

	&__logoImg  {
		display: block;
		width: auto; 
		height: auto;
    max-width: 100%;
    max-height: 2rem;
	}

	&__rating {
		width: 30%;
		font-size: 20px;
		line-height: 1.2;
		color: $color__text-main;

		.-firstItem & {
			font-family: $font__bold;
			font-size: 26px;
		}
	}

	&__stars {
		width: 30%;

		svg {
			display: block;
			width: 123px;
			height: 22px;
			margin-left: 1px;
		}

		@include laptop {
			.-firstItem & {
				svg {
					width: 156px;
					height: 28px;
					margin: 0;
				}
			}
		}
	}

	&__fineprint {
		margin-top: ($spacing_unit * .5);
		text-align: right; 
		font-family: $font__main;
		font-weight: 400;
		font-size: 12px;
		line-height: 1.4;
	}

}
