// WP utility alignment classes, generally for content and media embeds 
.alignleft {

	/*rtl:ignore*/
	float: left;

	/*rtl:ignore*/
	margin-right: 1.5em;
	margin-bottom: 1.5em;
}

.alignright {

	/*rtl:ignore*/
	float: right;

	/*rtl:ignore*/
	margin-left: 1.5em;
	margin-bottom: 1.5em;
}

.aligncenter {
	clear: both;

	@include center-block;

	margin-bottom: 1.5em;
}



// Utility alignment classes specific to text 
.textAlign {
	text-align: inherit;

	&_left {
		text-align: left;
	}

	&_center {
		text-align: center;
	}

	&_right {
		text-align: right;
	}
}
