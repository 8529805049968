// vertical tabs block
.verticalTabs {
	padding: ($spacing_unit * 2) 0;

	@include tablet {
		padding: ($spacing_unit * 4) 0;
	}

	&__title {
		text-align: center;
		font-size: 40px;
		line-height: 44px;
		letter-spacing: -0.5px;
		margin-bottom: $spacing_unit * 1.5;
	}

	&__wrapper {
		@include tablet {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}

	&__listContent {
		width: 100%;
		position: relative;
		display: flex;
		justify-content: start;
		align-items: center;

		@include tablet {
			max-width: 40%;
		}

		@include laptop {
			max-width: 34%;
		}
	}

	&__list {
		width: 100%;
		margin: 0;
		list-style-type: none;
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		@include tablet {
			flex-direction: column;
		}
	}

	&__listItem {
		display: inline-block;
		position: relative;
		z-index: 10;
		cursor: pointer;
		padding: 0;
		margin: 0;
		transition: $transition_duration;
		overflow: hidden;

		// &.-tabOpen {
		// 	background: pink;
		// }

		@include tablet {
			display: block;
		}
	}

	&__listItemLink {
		position: relative;
		text-decoration: none;
		border: none;
		display: block;
		padding: 5px 10px 20px;
		font-family: $font__bold;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		letter-spacing: -0.5px;
		color: $color__brand-lt-blue;
		transition: $transition_duration;

		@include tablet {
			padding: ($spacing_unit / 4) ($spacing_unit / 4) ($spacing_unit / 4) $spacing_unit;
			line-height: 36px;
		}

		.-tabOpen & {
			color: $color__brand-blue;

			@include tablet {
				font-size: 32px;
			}

			&:after {
				display: block;
				position: absolute;
				overflow: hidden;
				content: '';
				z-index: 2;
				height: 0;
				width: 0;
				bottom: 0;
				left: calc(50% - 10px);
				border-left: 10px transparent solid;
				border-right: 10px transparent solid;
				border-bottom: 10px $color__offwhite solid;

				@include tablet {
					left: unset;
					right: 0;
					bottom: calc(50% - 20px);
					border-top: 20px transparent solid;
					border-left: none;
					border-right: 20px $color__offwhite solid;
					border-bottom: 20px transparent solid;
				}
			}
		}

		&:hover {
			color: darken($color__brand-blue, 5%);
		}
	}

	&__listItemIcon {
		display: none;
		visibility: hidden;
		transition: opacity $transition_duration;

		@include tablet {
			display: inline-block;
			visibility: visible;
			position: relative;
			font-size: 16px;
			left: 10px;
			bottom: 2px;
		}

		.-tabOpen & {
			opacity: 0;
		}
	}

	&__tabContent {
		width: 100%;
		position: relative;
		display: flex;
		justify-content: start;
		align-items: center;
		background: $color__offwhite;
		border-radius: $border_radius;
	}

	&__tabPane {
		position: relative;
		width: 100%;
		display: block;
		visibility: hidden;
		margin-right: -100%;
		width: 100%;
		opacity: 0;
		overflow: hidden;
		transition: opacity $transition_duration ease-in-out;

		&.-tabOpen {
			opacity: 1;
			visibility: visible;
		}
	}

	&__tabPane-inner {
		padding: $spacing_unit;

		@include tablet {
			padding: $spacing_unit ($spacing_unit * 1.5);
		}
	}

	&__tabPane-text {
		text-align: left;
		font-size: 18px;
		line-height: 26px;
		padding: $spacing_unit 0;

		*:last-child {
			margin-bottom: 0;
		}
	}
}
