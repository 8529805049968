body,
button,
input,
select,
optgroup,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	font-size: $font__size-body;
	font-weight: $font__weight-body;
	line-height: $font__line-height-body;
}


