// Flickity v2.2.2
// https://flickity.metafizzy.co
// ---------------------------------------------- 

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: $color__brand-yellow;
  border: 1px solid $color__black;
  color: inherit;

  transition: $transition_duration;

  &:hover {
    background: $color__brand-yellow;
    cursor: pointer;



    // box-shadow: 5px 5px $color__black;
    // // transform: translate(-0.25rem,-0.25rem);
    // transform: translate(-0.3rem, -1.3rem);


  }

  &:focus {
    outline: 1px solid $color__black;
    box-shadow: 0 0 0 5px #19F;


    // transition: $transition_duration / 3;
    // box-shadow: 2px 2px $color__black;
    // transform: translate(-0.15rem, -1.15rem);
  }

  &:active {
    opacity: 0.6;

    // transition: $transition_duration / 3;
    // box-shadow: 2px 2px $color__black;
    // transform: translate(-0.15rem, -1.15rem);
  }

  &:disabled {
    opacity: 0.3;
    cursor: auto;
    // prevent disabled button from capturing pointer up event. #716 
    pointer-events: none;
  }
}



.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  // top: 50%;
  top: calc(50% - 3rem); // offset by bottom margin of blockquote 
  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
  // display: none;
  // visibility: hidden;
  // opacity: .5;

  @include tablet {
    // display: inline-block;
    // visibility: visible;
    top: calc(50% - 5rem); // offset by bottom margin of blockquote 
  }
}

.flickity-prev-next-button.previous { 
  // left: 10px; 
  left: $spacing_unit * .75;

  @include laptop {
    left: $spacing_unit;
  }
}
.flickity-prev-next-button.next { 
  // right: 10px; 
  right: $spacing_unit * .75;

  @include laptop {
    right: $spacing_unit;
  }
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 0;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 0;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 30%;
  top: 30%;
  width: 40%;
  height: 40%;

  .arrow {
    fill: $color__black; // color of the chevron 
    stroke: $color__brand-yellow; // chevron border, matching button background 
    stroke-width: 5; // gives illusion of smaller/thinner stroke chevron 
  }
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  // bottom: -36px;
  bottom: $spacing_unit * 1.5;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;

  @include tablet {
  bottom: $spacing_unit * 2.5;
  }

  // if option selected, move dots above testimonial 
  // .top-dots & {
  //   top: -36px;
  //   bottom: auto;
  // }

  // adjust for text/image block placement  
  .textAndImage & { 
    bottom: -30px;
  }
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  border-radius: 50%;
  // opacity: .5;
  cursor: pointer;
  border: 1px solid transparent;
  background: lighten($color__brand-primary, 20%);

}

.flickity-page-dots .dot.is-selected {
  // opacity: 1;
  background: $color__brand-primary;
  border-color: $color__black;
}
