p {
	margin-top: 0;
	margin-bottom: 1.5em;
	position: relative;
	z-index: 1;
}

b,
strong {
	font-family: $font__bold;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

pre {
	background: $color__background-pre;
	font-family: $font__pre;
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {
	font-family: $font__code;
	font-size: 80%;
}

code {
	background: $color__brand-lt-gray;
}

abbr,
acronym {
	border-bottom: 1px dotted $color__brand-md-gray;
	cursor: help;
}

mark,
ins {
	background: $color__background-ins;
	text-decoration: none;
}

big {
	font-size: 125%;
}
