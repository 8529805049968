// Landing Page Hero block styles
.landingPageHero {
	position: relative;
	// text-align: center;
	overflow: hidden;
	background: $color__white;
	padding: 0; 

	&__cta {
		position: relative;
		z-index: 3;
		margin-left: auto;
		margin-right: auto;
		max-width: $container_max_width;
		padding: 0 $spacing_unit;
		margin: $spacing_unit auto 0;
		position: relative;
		z-index: 5;

		@include tablet {
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	&__title {
		margin: 0 auto 1rem;
		font-size: 42px;
		line-height: 1.1;

		// wp block color override
		&.-customColor {
			color: inherit;
		}

		@include tablet {
			max-width: 100%;
			margin: 0 0 1rem;
		}

		@include laptop {
			font-size: 52px;
		}
	}

	&__text {
		margin: 0 auto $spacing_unit;
		max-width: 540px;

		@include tablet {
			text-align: left;
			padding-right: calc($spacing_unit * 2);
			margin: 0;
			flex: 1;
			max-width: 420px;
			margin: calc($spacing_unit * 2) 0;
		}

		// logo
		.siteHeader__branding {
			margin-bottom: calc($spacing_unit * 3);
		}

		*:last-child {
			margin-bottom: 0;
		}
	}

	&__copy {
		margin: 0 auto 1.5em;
		letter-spacing: -.5px;

		@include tablet {
			letter-spacing: unset;
		}

		@include laptop {
			margin: 0 0 3rem;
		}
	}



	&__image {
		flex-shrink: 0;
		pointer-events: none;

		@include tablet {
			flex: 1;
			position: absolute;
			bottom: 126px;
			left: 0;
			width: 50%;
		}

		@include widetablet {
			width: 72%;

		}

		@include laptop {
			width: 68%;
			bottom: 166px;
		}

		img {
			display: block;
			width: auto;
			max-width: 100%;
			max-height: 500px;
			margin: 0 auto;

			@include tablet {
				max-height: 540px;
			}

			@include widetablet {
				max-height: 560px;
			}

			@include laptop {
				min-height: 600px;
				max-height: 600px;
			}
		}
	}


	&__form {
		background: $color__brand-yellow;
		margin-left: -$spacing_unit;
		margin-right: -$spacing_unit;

		@include tablet {
			flex: 1;
			max-width: 320px;
			background: transparent;
			margin: 0;
		}

		@include laptop {
			max-width: 380px;
		}

		.marketoForm, 
		.ninjaForm {
			padding: $spacing_unit $spacing_unit calc($spacing_unit * 1.5);

			@include tablet {
				padding: calc($spacing_unit * 1.5) 0;
			}
		}
	}

	// abstract shape behind the featured image / portrait 
	&__shape {
		flex-shrink: 0;
		position: absolute;
    height: 110%;
		left: 0;
		right: 0;
    width: auto;
    bottom: 0;
		z-index: 3;

		@include tablet {
			height: 100%;
			top: 0;
			bottom: -40%;
			left: auto;
			max-width: 60%;
		}

		@include hdtv {
			right: -80px;
			height: 150%;
		}

		img {
			display: block;
			margin: 0;
			transform: rotate(90deg);
			transform-origin: center;
			width: 100%;
			height: 100%;

			@include tablet {
				transform: none;
				height: 100%;
			}
		}
	}

	&__band {
		position: relative; 
		height: 64px;
		background: $color__brand-dk-green;
		z-index: 3;
		display: none;

		@include tablet {
			display: block;
			height: 126px;
			z-index: 1;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
		}

		@include laptop {
			height: 166px;
		}
	}

	&__band--mobile {
		display: block;
		right: 40px;
		width: 799px;

		@include tablet {
			display: none;
		}
	}

}
