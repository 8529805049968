
// styles specific to Marketo forms 
.marketoForm {
	padding: 0;

	.mktoForm {
		width: 100% !important;

		.mktoLabel {
			display: block;
			width: 100% !important;
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			font-size: 12px;
			line-height: 24px;
			color: $color__black;
		}

		.mktoFormCol {
			float: unset !important;
			position: relative;
			min-height: 2em;
		}

		.mktoFieldDescriptor.mktoFormCol {
			margin-bottom: $spacing_unit / 2 !important;
		}

		.mktoGutter,
		.mktoOffset {
			display: none !important;
			visibility: hidden !important;
		}

		.mktoFieldWrap {
			float: unset !important;
			width: 100% !important;
		}

		span.mktoButtonWrap.mktoBlueBox {
			margin: 0 !important;
		}

		input[type="text"],
		input[type="email"],
		input[type="url"],
		input[type="password"],
		input[type="search"],
		input[type="number"],
		input[type="tel"],
		input[type="range"],
		input[type="date"],
		input[type="month"],
		input[type="week"],
		input[type="time"],
		input[type="datetime"],
		input[type="datetime-local"],
		input[type="color"],
		select.mktoField,
		textarea {
			display: block; 
			width: 100% !important;
			color: $color__text-input;
			border: 1px solid $color__black;
			font-size: 16px;
			line-height: 24px;
			border-radius: $border_radius;
			padding: 12px 24px;
			box-shadow: none;
			min-height: 50px;

			&::placeholder {
				color: $color__brand-md-gray2;
			}

			&:focus {
				color: $color__text-input-focus;
				border-color: $color__brand-primary;
				outline-color: $color__brand-primary;
			}
		}

		select {
			display: block; 
			width: 100% !important;
			max-width: 100% !important;
			color: $color__text-input;
			border: 1px solid $color__black;
			font-size: 16px;
			line-height: 24px;
			border-radius: $border_radius;
			padding: 12px 24px;
			text-transform: none;
			box-shadow: none;
		}

		textarea {
			display: block; 
			width: 100% !important;
			border: 1px solid $color__black;
			box-shadow: none;
		}

		.mktoAsterix {
			float: unset !important;
			color: $color__brand-red;
			padding-left: 5px;
			display: inline-block;
		}

		span.mktoButtonWrap.mktoBlueBox {
			display: block;
			margin: ($spacing_unit * .75) 0 0 !important;
		}

		.mktoButtonWrap.mktoBlueBox button.mktoButton {
			@extend .button;
		}

	}

}
